<template>
  <div>
    <div class="p-1 text-center">
      <h4 class="mb-3">Are you sure you want to set this order status as <b>ACTIVE</b>?</h4>

      <b-button
        v-if="!isLoading"
        variant="primary"
        class="mt-2 w-100"
        @click.prevent="handleUpdateStatus"
      >
        Submit
      </b-button>
      <b-button v-else class="mt-2 w-100" variant="primary" :disabled="true">
        <b-spinner />
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  name: 'PaidOrder',
  props: {
    order: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async handleUpdateStatus() {
      this.isLoading = true
      const response = await axios.patch(`fleet_update_status/${this.order.id}`, {
        status: 1,
      })
      if (response.status === 422) {
        this.$refs.updateStatus.setErrors(response.data.errors)
        this.isLoading = false
      } else if (response.status === 200) {
        this.$swal({
          title: 'ORDER ACTIVE',
          text: 'You have successfully updated the order status.',
          icon: 'success',
          customClass: {
            confirmButton: 'bg-success',
          },
        })
        this.$emit('success')
        this.isLoading = false
      } else {
        this.$swal({
          title: 'Error',
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        this.isLoading = false
      }
    },
  },
}
</script>
